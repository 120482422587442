<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.outbound')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('outboundloadsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('outboundloadsview')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="outbound-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(load_number)="{ item, index, detailsShowing}">
                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                            </a>
                            {{ item.load_number }}
                        </template>
                        <template v-slot:cell(dealer_id)="{item}">
                            {{item.dealer ? item.dealer.name : ''}}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{(record.item.supplier_carrier || {}).name}}
                        </template>
                        <template v-slot:cell(from_location_id)="record">
                            <print-ad :titled="1" :item="record.item.from_location"></print-ad>
                        </template>
                        <template v-slot:cell(to_location_id)="record">
                            <print-ad :titled="1" :item="record.item.to_location"></print-ad>
                        </template>
                        <template v-slot:cell(vin_number)="record">
                            <span v-if="record.item.outbound_load_orders && record.item.outbound_load_orders.length > 0">
                                <span v-for="item in record.item.outbound_load_orders"> {{item.order.vin_number}}, </span>
                            </span>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(actual_loading_time)="record">
                            <span v-if="record.item.actual_loading_time">
                                {{$global.utcDateToLocalDate(record.item.actual_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(status)="record">
                            <obl-status :status="record.item.status"></obl-status>
                        </template>
                        <template v-slot:cell(logistic_type)="record">
                            <div v-for="(olo,index) in record.item.outbound_load_orders">
                                <span class="text-capitalize">
                                    <span
                                        v-if="olo.order.tr_status_type == 'outbound'">{{$t('title.outbound')}}</span>
                                    <span
                                        v-if="olo.order.tr_status_type == 'reexport'">{{$t('title.reexport')}}</span>
                                    <span
                                        v-if="olo.order.tr_status_type == 'dd'">D2D</span>
                                    <span v-if="olo.order.tr_status_type == 'cc'">CC</span>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')"
                               v-if="$global.hasPermission('outboundloadsupdate') && $global.hasAnyRole(['superadmin', 'efl'])
                                && record.item.status <= 3"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('outboundloadsdestroy') && record.item.status <= 3">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1" :title="$t('button.title.deleteItem')" v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('outboundloadsdetail') && $global.hasAnyRole(['superadmin', 'efl', 'efluser'])"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item><strong>{{$t('column.comments')}}</strong>:
                                        {{item.comments}}
                                    </b-list-group-item>
                                    <b-list-group-item><strong>{{$t('column.models')}}</strong>:
                                        <span v-for="(ol, index) in item.outbound_load_orders">
                                            {{(ol.order.model || {}).title}}
                                        </span>
                                    </b-list-group-item>
                                    <b-list-group-item><strong>{{$t('column.skipDealerConfirmation')}}</strong>:
                                        {{item.skip_dealer_confirmation ? $t('msc.yes') : $t('msc.no')}}
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.outbound-table-->
                <div class="outbound-operation">
                    <div v-if="operation === 'edit'">
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                            </b-row>
                            <div v-show="global.pendingRequests <= 0">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.supplierCarrier')+' *'"
                                            label-for="supplier_id"
                                            :invalid-feedback="formErrors.first('supplier_carrier_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.suppliers"
                                                placeholder=""
                                                v-model="formFields.supplier_carrier_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="comment"
                                            :invalid-feedback="formErrors.first('comments')">
                                            <b-textarea
                                                id="comment"
                                                v-model="formFields.comments"
                                                type="text"
                                                :state="((formErrors.has('comments') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="form-group">
                                            <div class="clearfix">
                                                <span class="pull-left">
                                                    <label class="d-block">{{$t('input.order')}}</label>
                                                </span><!-- /.pull-left -->
                                                <span class="pull-left ml-2 mb-1">
                                                    <button class="btn btn-warning btn-sm" v-b-tooltip.hover
                                                            :title="$t('button.title.addNewOrder')" type="button"
                                                            @click="handleAddOrderClick">{{$t('button.add')}}</button>
                                                </span><!-- /.pull-left -->
                                            </div><!-- /.clearfix -->
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.orders"
                                                placeholder=""
                                                v-model="selectedOrderId"
                                                :class="[{'invalid is-invalid': (formErrors.has('order_id'))}]"/>
                                            <div class="invalid-feedback">{{formErrors.first('order_id')}}</div>
                                        </div>
                                    </b-col>
                                    <b-col sm="6" v-if="formFields.status == 3">
                                        <b-form-group
                                            :label="$t('input.status') + ':'"
                                            :invalid-feedback="formErrors.first('announce')"
                                        >
                                            <b-form-checkbox v-model="formFields.announce" name="announce">
                                                {{$t('title.announce')}}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12" v-if="formFields.status < 5">
                                        <div class="form-group mb-1 mt-2">
                                            <b-form-checkbox v-model="formFields.skip_dealer_confirmation"
                                                             name="check-button">{{$t('input.skipDealerConfirmation')}}
                                            </b-form-checkbox>
                                        </div>
                                    </b-col>
                                    <b-col sm="12">
                                        <h3 class="text-secondary">{{$t('title.orders')}}</h3>
                                        <hr/>
                                        <small class="text-danger" v-if="formErrors.has('orders')">{{formErrors.first('orders')}}</small>
                                        <table class="table table-hover"
                                               v-if="editLoad && editLoad.outbound_load_orders">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{$t('column.orderId')}}</th>
                                                <th>{{$t('column.fromLocation')}}</th>
                                                <th>{{$t('column.toLocation')}}</th>
                                                <th>{{$t('column.destinationLocation')}}</th>
                                                <th>{{$t('column.client')}}</th>
                                                <th>{{$t('column.dealer')}}</th>
                                                <th>{{$t('column.action')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(olo, index) in editLoad.outbound_load_orders"
                                                :class="{'bg-light-danger': _.includes(formFields.deleted_orders, olo.order.id)}">
                                                <td>{{index + 1}}</td>
                                                <td>{{olo.order.order_id}}</td>
                                                <td>
                                                    <print-ad :item="olo.from_location"></print-ad>
                                                </td>
                                                <td>
                                                    <print-ad :item="olo.to_location"></print-ad>
                                                </td>
                                                <td>
                                                    <print-ad :item="olo.order.dealer_location"></print-ad>
                                                </td>
                                                <td>{{olo.order.client.company_name}}</td>
                                                <td>{{olo.order.dealer.name}}</td>
                                                <td>
                                                    <a-popconfirm title="Are you sure？"
                                                                  @confirm="handleDeleteOrderClick(olo.order.id)"
                                                                  v-if="!_.includes(formFields.deleted_orders, olo.order.id)">
                                                        <i slot="icon" class="fe fe-trash"></i>
                                                        <a class=" ml-1"
                                                           :title="$t('button.title.deleteItem')"
                                                           v-b-tooltip.hover>
                                                            <i class="fe fe-trash"></i>
                                                        </a>
                                                    </a-popconfirm>
                                                    <a class=" ml-1"
                                                       v-if="_.includes(formFields.deleted_orders, olo.order.id) && formFields.new_orders.length <= 0"
                                                       @click="handleUndoDeleteClick(olo.order.id)"
                                                       :title="$t('button.title.undo')"
                                                       v-b-tooltip.hover>
                                                        <i class="fa fa-undo"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <h3 class="text-secondary">{{$t('title.newOrders')}}</h3>
                                        <hr/>
                                        <table class="table table-hover"
                                               v-if="editLoad && formFields.new_orders.length">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{$t('column.orderId')}}</th>
                                                <th>{{$t('column.fromLocation')}}</th>
                                                <th>{{$t('column.toLocation')}}</th>
                                                <th>{{$t('column.client')}}</th>
                                                <th>{{$t('column.dealer')}}</th>
                                                <th>{{$t('column.action')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(olo, index) in formFields.new_orders">
                                                <td>{{index + 1}}</td>
                                                <td>{{olo.order_id}}</td>
                                                <td>
                                                    <print-ad :item="olo.from_location"></print-ad>
                                                </td>
                                                <td>
                                                    <print-ad :item="olo.to_location"></print-ad>
                                                </td>
                                                <td>{{olo.client.company_name}}</td>
                                                <td>{{olo.dealer.name}}</td>
                                                <td>
                                                    <a-popconfirm title="Are you sure？"
                                                                  @confirm="handleDeleteNewOrderClick(olo.id)">
                                                        <i slot="icon" class="fe fe-trash"></i>
                                                        <a class=" ml-1"
                                                           :title="$t('button.title.deleteItem')"
                                                           v-b-tooltip.hover>
                                                            <i class="fe fe-trash"></i>
                                                        </a>
                                                    </a-popconfirm>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <b-row class="operation-footer">
                                            <b-col sm="12">
                                                <b-button
                                                    size="sm"
                                                    type="submit"
                                                    variant="primary"
                                                    :disabled="global.pendingRequests > 0"
                                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                                >
                                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                 size="12px"
                                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                                    <i class="fa fa-save mr-1"></i>
                                                    {{$t('button.save')}}
                                                </b-button>
                                                <b-button variant="warning" class="ml-3"
                                                          size="sm" @click="handleOperationClose()"
                                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>
                        </form>
                    </div>
                </div><!--/.outbound-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.loadingPoint"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.unloadingPoint"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="transportOrders"
                            :fetch="handleExcelExportSubmitClick"
                            name="tranport_orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import qs from 'qs'
    import moment from 'moment-timezone'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"

    const FORM_STATE = {
        supplier_carrier_id: null,
        supplier_compound_id: null,
        id: null,
        status: 2,
        skip_dealer_confirmation: false,
        announce: false,
        limit: 1, // loading_factors
        comments: null,
        outbound_load_orders: [],
        _method: 'post',
        deleted_orders: [],
        new_orders: [],
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        status: [1, 2, 3, 4, 5, 6, 7],
        loadingPoint: [],
        unloadingPoint: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
            class: 'w-130'
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.dealer'),
            key: 'dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.vinNumber'),
            key: 'vin_number',
            sortable: false,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'actual_loading_time',
            sortable: false,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['outboundloadsupdate', 'outboundloadsview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ExcelExportMixin],
        components: {
            Treeselect,
            Detail,
            JsonExcel,
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'outbound/loads',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    suppliers: [],
                    orders: [],
                    countries: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    status: [
                        {id: 1, label: this.$t('title.transportOrder')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.pickUp')},
                        {id: 6, label: this.$t('title.stockExitInTransit')},
                        {id: 7, label: this.$t('title.deliveryRequested')},
                        {id: 8, label: this.$t('title.delivered')},
                    ],
                    loadingPoint: [],
                    unloadingPoint: [],
                },
                show: true,
                editLoad: null,
                selectedOrderId: null
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getDrivers();
            this.getTransportVehicles();
            this.getLocations();
            this.getCountries()
            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.outbound')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                if ((this.editLoad.outbound_load_orders.length + this.formFields.new_orders.length) === this.formFields.deleted_orders.length) {
                    const errors = {orders: [this.$t('validation.minimumOrderRequired')]}
                    this.formErrors = new Error(errors)
                    return false;
                }

                try {
                    const params = {
                        id: this.formFields.id,
                        supplier_carrier_id: this.formFields.supplier_carrier_id,
                        supplier_compound_id: this.formFields.supplier_compound_id,
                        comments: this.formFields.comments,
                        _method: 'post',
                        deleted_orders: this.formFields.deleted_orders,
                        new_orders: this.formFields.new_orders.map((item) => ({id: item.id})),
                        status: this.formFields.status,
                        skip_dealer_confirmation: this.formFields.skip_dealer_confirmation,
                        announce: this.formFields.announce,
                    }

                    const price = _.find(this.dropdowns.suppliers, {id: this.formFields.supplier_carrier_id}).prices[0];
                    if (price) {
                        params.price_id = price.id;
                        params.price_period_id = price.price_periods[0].id;
                        const route = price.route;
                        const zones = _.filter(route.to_zone.zip_ranges, ({zip_from, zip_to}) => _.inRange(this.editLoad.to_location.zip * 1, zip_from * 1, zip_to * 1 + 1))

                        if (zones.length > 0) {
                            params.zone_id = zones[0].id;
                        }
                    } else {
                        this.priceNotFound()
                        return false
                    }

                    const response = await request({
                        url: 'outbound/loads/update',
                        method: 'post',
                        data: {...params},
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                this.dropdowns.orders = []
                this.formFields.new_orders = []
                this.formFields.deleted_orders = []
                request({url: `/outbound/loads/detail/${id}`})
                    .then((response) => {
                        const {data} = response
                        this.editLoad = data
                        this.formFields.id = data.id
                        this.formFields.supplier_carrier_id = (data.supplier_carrier ? data.supplier_carrier.id : null)
                        this.formFields.supplier_compound_id = (data.supplier_compound ? data.supplier_compound.id : null)
                        this.formFields.status = data.status
                        this.formFields.skip_dealer_confirmation = data.skip_dealer_confirmation
                        this.formFields.limit = data.qty
                        this.getOrderNearLocationSupplier(data.outbound_load_orders.map(item => item.order.id), data.created_at)
                        this.getOrders(data)
                        this.operationTitle = this.$t('title.editLoad') + `#${data.load_number}`
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/outbound/loads/delete',
                        data: {
                            id: id,
                        },
                    })

                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async getOrderNearLocationSupplier(ids, created_at = null) {
                try {
                    const response = await request({
                        url: '/dropdowns/orders/matched/dealer/location/carrier/suppliers',
                        method: "post",
                        data: {
                            orders: ids,
                            created_at: (created_at ? moment(created_at).format('YYYY-MM-DD') : null)
                        }
                    })

                    const {data} = response
                    this.dropdowns.suppliers = data
                } catch (e) {
                    this.dropdowns.suppliers = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.loadingPoint = data
                    this.dropdowns.unloadingPoint = data
                } catch (e) {
                    this.dropdowns.loadingPoint = []
                    this.dropdowns.unloadingPoint = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            validateOrderError() {
                const errors = {}
                let hasError = false
                if (!this.selectedOrderId) {
                    errors.order_id = [this.$t('validation.required')]
                    hasError = true
                }

                const existingOrders = this.editLoad.outbound_load_orders.length - this.formFields.deleted_orders.length
                if ((existingOrders + this.formFields.new_orders.length + 1) > this.formFields.limit) {
                    errors.order_id = [this.$t('validation.orderLimitExceed')]
                    hasError = true
                }

                if (_.find(this.editLoad.outbound_load_orders, {id: this.selectedOrderId})) {
                    errors.order_id = [this.$t('validation.duplicate')]
                    hasError = true
                }

                this.formErrors = new Error(errors)
                return hasError
            },
            handleAddOrderClick() {
                if (this.validateOrderError())
                    return false

                this.formFields.new_orders.push(_.find(this.dropdowns.orders, {id: this.selectedOrderId}).item)
                this.selectedOrderId = null
            },
            handleDeleteNewOrderClick(orderId) {
                const orders = this.formFields.new_orders
                orders.splice(orderId, 1)
                this.formFields.new_orders = orders
            },
            async getOrders(item) {
                try {
                    const params = {
                        id: item.id,
                        to_location: item.to_location.id,
                        brands: (_.map(item.outbound_load_orders, (item) => item.order.brand.id)),
                        dealer: item.outbound_load_orders[0].order.dealer.id,
                        dealer_location: item.outbound_load_orders[0].order.dealer_location.id,
                    }

                    const {data} = await request({
                        method: "post",
                        url: 'dropdowns/orders/outbound/update',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    this.dropdowns.orders = data
                        .map(item => ({
                            id: item.id,
                            label: `${item.order_id} | ${item.vin_number}`,
                            item: item
                        }))

                } catch (e) {
                    console.log(e)
                }
            },
            handleDeleteOrderClick(orderId) {
                this.formFields.deleted_orders.push(orderId)
            },
            handleUndoDeleteClick(orderId) {
                const orders = this.formFields.deleted_orders
                orders.splice(orderId, 1)
                this.formFields.deleted_orders = _.compact(orders)
            },
            hasListAccess() {
                return this.$global.hasPermission('outboundloadsview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.editLoad = null
                this.dropdowns.suppliers.length = []
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx {
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn {
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>

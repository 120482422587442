import {request} from "../../util/Request"
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'load_number', label: this.$t('title.loadNumber'), show: true},
                    {id: 'supplier_carrier', label: this.$t('title.supplierCarrier'), show: true},
                    {id: 'from_location', label: this.$t('title.loadingPoint'), show: true},
                    {id: 'to_location', label: this.$t('title.destinationTown'), show: true},
                    {id: 'dealer', label: this.$t('title.dealer'), show: true},
                    {id: 'transport_vehicle', label: this.$t('title.transportVehicle'), show: true},
                    {id: 'actual_loading_time', label: this.$t('title.actualLoadingDate'), show: true},
                    {id: 'actual_unloading_time', label: this.$t('title.actualUnLoadingDate'), show: true},
                    {id: 'qty', label: this.$t('title.qty'), show: true},
                    {id: 'status', label: this.$t('title.status'), show: true},
                    {id: 'updated_at', label: this.$t('title.modifiedAt'), show: true},
                ],
                headers: {
                    [this.$t('column.loadNumber')]: 'load_number',
                    [this.$t('column.vin')]: 'vin_number',
                    [this.$t('column.brand')]: 'brand_id',
                    [this.$t('column.model')]: 'model_id',
                    [this.$t('column.supplierCarrier')]: 'supplier_carrier',
                    [this.$t('column.loadingPoint')]: 'from_location',
                    [this.$t('column.destinationTown')]: 'to_location',
                    [this.$t('column.dealer')]: 'dealer',
                    [this.$t('column.transportVehicle')]: 'transport_vehicle',
                    [this.$t('column.actualLoadingDate')]: 'actual_loading_time',
                    [this.$t('column.actualUnLoadingDate')]: 'actual_unloading_time',
                    [this.$t('column.qty')]: 'qty',
                    [this.$t('column.status')]: 'status',
                    [this.$t('column.modifiedAt')]: 'updated_at',
                }
            },
        }
    },
    methods: {
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })

            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'outbound/loads/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => _.pick({
                load_number: this.$global.val(item.load_number),
                supplier_carrier: this.$global.val((item.supplier_carrier || {}).name),
                from_location: this.$global.val((item.from_location || {}).label),
                to_location: this.$global.val((item.to_location || {}).label),
                dealer: this.$global.val((item.dealer || {}).name),
                transport_vehicle: (item.transport_vehicle ? (item.transport_vehicle.truck_plate_number ? item.transport_vehicle.truck_plate_number : "") + (item.transport_vehicle.trailer_plate_number ? "|" + item.transport_vehicle.trailer_plate_number : "") : ""),
                loading_factors: this.$global.val(item.loading_factors),
                qty: this.$global.val(item.qty),
                actual_loading_time: (item.actual_loading_time ? this.$global.val(this.$global.utcDateToLocalDate(item.actual_loading_time)) : ""),
                actual_unloading_time: (item.actual_unloading_time ? this.$global.val(this.$global.utcDateToLocalDate(item.actual_unloading_time)) : ""),
                status: this.$global.val(item._status),
                updated_at: this.$global.val(this.$global.utcDateToLocalDate(item.updated_at)),
            }, columns))

            if (columns.length >= 3) {
                rows.push({});
                rows.push({});
                rows.push({});
                rows.push({[columns[0]]: this.$t('title.orders')});
                rows.push({
                    [columns[0]]: this.$t('title.loadNumber'),
                    [columns[1]]: this.$t('title.vinNumber'),
                    [columns[2]]: this.$t('title.brand'),
                    [columns[3]]: this.$t('title.model'),
                    [columns[4]]: this.$t('title.client'),
                });

                _.map(data, (item, index) => {
                    _.map(item.outbound_load_orders, ({order}, index) => {
                        rows.push({
                            [columns[0]]: item.load_number,
                            [columns[1]]: order.vin_number,
                            [columns[2]]: (order.brand || {}).title,
                            [columns[3]]: (order.model || {}).title,
                            [columns[4]]: (order.client || {}).company_name,
                        });
                    })
                })
            }

            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    },
}
